module.exports = function menu() {
    var images = document.querySelectorAll('.stock_item-img');
    var imagesArr = Array.prototype.slice.call(images);
    var imagesHeight = new Array();
    //выравнивание высоты изображений при размере экрана больше 425px 
    if (window.innerWidth > 425) {
        //получаем высоту всех фотографий
        for (let i = 0; i < imagesArr.length; i++) {
            imagesHeight.push(imagesArr[i].offsetHeight);
        }

        //находим максимальную высоту фотографий
        const max = Math.max.apply(null, imagesHeight);

        //присваиваем максимульную высоту всем фотографиям в списке
        for (let i = 0; i < imagesArr.length; i++) {
            imagesArr[i].style.height = `${max}px`
        }
    }
};
